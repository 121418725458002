import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { scenarioRefinanceOption } from "../../../../commons/enum";
import { useLinkedBankAccountData } from "../../../../hooks/account.hooks";
import { usePropertyList } from "../../../../hooks/property.hooks";
import Plus from "../../../../svgs/Plus";
import { convertToInt } from "../../../../utils/convert-number";
import { calculateLvr } from "../../../../utils/scenario-utils";
import ScenarioFilterButton from "./scenario-filter-button";
import ScenarioLoanToValueRatio from "./scenario-loan-to-value-ratio";
import ScenarioPropertyItem from "./scenario-property-item";

const calculateDefaultHomeLoan = (propertyData, propertyLoan) => {
  let totalLoan = 0;
  const {
    propertyId: firstPropertyId,
    accountIds,
    estimate,
    monthlyRent,
  } = propertyData;

  const loanListData = propertyLoan.reduce((loanList = [], item) => {
    const { accountId = "" } = item;
    if (accountIds.indexOf(accountId) !== -1) {
      loanList.push(item);
    }
    return loanList;
  }, []);
  if (loanListData.length > 0) {
    totalLoan = loanListData.reduce((result, init) => {
      const { balance } = init;
      const tempResult = result + convertToInt(balance);
      return tempResult;
    }, 0);
  }
  const lvr = calculateLvr(estimate, estimate - Math.abs(totalLoan));
  return {
    propertyId: firstPropertyId,
    lvrSetting: {
      lvr,
      estimateProperty: estimate,
      cashTowardProperty: estimate - totalLoan,
      monthlyRent,
    },
  };
};

const ScenarioRefinance = ({
  filterSelect,
  setScenarioFilter,
  cashDeposit,
}) => {
  const { data: propertyList } = usePropertyList();
  const linkedBankAccountData = useLinkedBankAccountData() || {};
  const { propertyLoan = [] } = linkedBankAccountData;
  const [selectedScenarioProperty, setSelectedScenarioProperty] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const { lvrSetting = {} } = filterSelect;

  const filteredProperties = useMemo(() => {
    return propertyList?.filter((item) => item.accountIds) || [];
  }, [propertyList]);

  const refinanceOptionList = useMemo(() => {
    let refinanceOption = [
      {
        text: "Add Manually",
        key: scenarioRefinanceOption.ADD_MANUALLY,
      },
    ];
    if (filteredProperties.length) {
      refinanceOption = [
        {
          text: "Connect Loan",
          key: scenarioRefinanceOption.SELECT_LOAN,
        },
        ...refinanceOption,
      ];
    }
    return refinanceOption;
  }, [filteredProperties]);

  useLayoutEffect(() => {
    setSelectedOption(refinanceOptionList[0].key);
  }, [refinanceOptionList]);

  useEffect(() => {
    if (!selectedScenarioProperty && filteredProperties.length > 0) {
      const { propertyId: firstPropertyId } = filteredProperties[0];
      const {
        propertyId,
        lvrSetting: homeloanLvrSetting,
      } = calculateDefaultHomeLoan(filteredProperties[0], propertyLoan);

      setScenarioFilter({
        ...filterSelect,
        propertyId,
        lvrSetting: { ...lvrSetting, ...homeloanLvrSetting },
      });
      setSelectedScenarioProperty(firstPropertyId);
    }
  }, [
    filterSelect,
    filteredProperties,
    lvrSetting,
    propertyList,
    propertyLoan,
    selectedScenarioProperty,
    setScenarioFilter,
  ]);

  const handleSetSelectScenarioProperty = (value) => {
    setSelectedScenarioProperty(value);
  };
  const handleSelectRefinanceOption = (value) => {
    if (value === scenarioRefinanceOption.ADD_MANUALLY) {
      setScenarioFilter({
        ...filterSelect,
        propertyId: "",
      });
    }
    setSelectedOption(value);
  };
  const handleSelectScenarioProperty = (value) => {
    const { lvrSetting: valueLvrSetting, propertyId } = value;
    setScenarioFilter({
      ...filterSelect,
      propertyId,
      lvrSetting: { ...lvrSetting, ...valueLvrSetting },
    });
  };

  const generateRefinanceOption = (refinanceOption) => {
    if (refinanceOption === scenarioRefinanceOption.SELECT_LOAN) {
      return (
        <>
          <div className="scenario-filter-title">Home to Refinance</div>
          <div className="scenario-lvr-setting">
            {propertyList
              ?.filter((item) => item.accountIds)
              .map((propertyItem) => {
                const {
                  addressDetails,
                  propertyId,
                  accountIds,
                  estimate,
                } = propertyItem;
                const loanListData = propertyLoan.reduce(
                  (loanList = [], item) => {
                    const { accountId = "" } = item;
                    if (accountIds.indexOf(accountId) !== -1) {
                      loanList.push(item);
                    }
                    return loanList;
                  },
                  []
                );
                return (
                  <div key={propertyItem.propertyId}>
                    <ScenarioPropertyItem
                      addressDetails={addressDetails}
                      key={`scenarioProperty${propertyId}`}
                      loanListData={loanListData}
                      propertyId={propertyId}
                      estimateProperty={estimate}
                      selectedScenarioProperty={selectedScenarioProperty}
                      setSelectedScenarioProperty={
                        handleSetSelectScenarioProperty
                      }
                      handleSelectScenarioProperty={
                        handleSelectScenarioProperty
                      }
                    />
                  </div>
                );
              })}
          </div>
        </>
      );
    }

    return (
      <ScenarioLoanToValueRatio
        refinanceOption={refinanceOption}
        setScenarioFilter={setScenarioFilter}
        filterSelect={filterSelect}
        cashDeposit={cashDeposit}
        title={
          filteredProperties.length > 0
            ? "Loan Selector"
            : "Property and Loan Details"
        }
      />
    );
  };

  return (
    <>
      {filteredProperties.length > 0 && (
        <div className="scenario-lvr-setting-container">
          <div className="scenario-filter-title">
            Connect Loan or Add Manually
          </div>
          <div className="scenario-filter-item-container">
            {refinanceOptionList.map((refinanceOption) => {
              return (
                <ScenarioFilterButton
                  key={refinanceOption.key}
                  buttonText={refinanceOption?.text}
                  onClick={() =>
                    handleSelectRefinanceOption(refinanceOption.key)
                  }
                  icon={<Plus />}
                  isSelected={selectedOption === refinanceOption.key}
                />
              );
            })}
          </div>
        </div>
      )}

      {generateRefinanceOption(selectedOption)}
    </>
  );
};

ScenarioRefinance.propTypes = {
  filterSelect: PropTypes.shape({
    scenarioType: PropTypes.string,
    lvrSetting: PropTypes.shape({
      estimateProperty: PropTypes.number,
      cashDeposit: PropTypes.number,
      equityAvailable: PropTypes.number,
      monthlyRent: PropTypes.number,
      cashAvailable: PropTypes.number,
    }),
  }),
};

export default ScenarioRefinance;
